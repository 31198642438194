import React, { useState, useCallback, useEffect } from "react";

import { toast } from "react-toastify";
import hexToRGBA from "../helper/hexToRbga";
import { CloseCircleOutlined } from "@ant-design/icons";
const UploadPicture = ({
  themeColor = "#E26851",
  fileData,
  title = "Upload Profile Picture",
  valuePicture,
  setViewPicture,
}) => {
  const [previewUrl, setPreviewUrl] = useState("");
  const [profileUser, setProfile] = useState(null);

  useEffect(() => {
    fileData(profileUser);
  }, [profileUser]);

  const beforeUpload = useCallback((file, header) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";

    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/JPEG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isLt2M) {
      toast.error("Image must smaller than 1MB!");
    }

    if (isJpgOrPng && isLt2M) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      setProfile(file);
    }
  }, []);

  const handleRemove = () => {
    setPreviewUrl("");
    setViewPicture("");
    setProfile(null);
  };

  return (
    <div
      style={{
        border: `1px solid ${hexToRGBA(themeColor, 0.2)}`,
      }}
      className="flex px-8 py-4 justify-between items-center my-4"
    >
      {previewUrl ? (
        <div className="flex gap-4">
          <img src={previewUrl} className="w-40" alt="" />
          <CloseCircleOutlined onClick={handleRemove} />
        </div>
      ) : valuePicture ? (
        <div className="flex gap-4">
          <img src={valuePicture} className="w-40" alt="" />
          <CloseCircleOutlined onClick={handleRemove} />
        </div>
      ) : (
        <p className="font-semibold m-0">{title}</p>
      )}
      <div className="flex flex-col justify-center items-center ">
        {previewUrl && <p className="font-semibold m-0">{title}</p>}
        <input
          type="file"
          id="logo_image"
          style={{ display: "none" }}
          onChange={(event) => {
            beforeUpload(event.target.files[0], true);
          }}
        />
        <label
          htmlFor="logo_image"
          style={{
            backgroundColor: themeColor,
          }}
          className="py-2 px-8 font-bold text-white cursor-pointer hover:opacity-70"
        >
          Browse File
        </label>
        <p className="mt-2 text-xs font-normal" style={{ fontSize: 10 }}>
          Min 300 x 300 px - max 1 MB file size
        </p>
      </div>
    </div>
  );
};

export default UploadPicture;
