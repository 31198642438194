import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import "./login.styles.scss";

import { Col } from "react-bootstrap";

import {
  chooseStudio,
  setStatusDefault,
} from "../../redux/studio/studio.action";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../services/generate-error-message";
import { getAllActiveStudio } from "../../services/auth";
import OrganismLayout from "../../components/Layout/organism-layout";
import logo from "./../../assets/images/gigs-logo.svg";
import { getUserRole } from "../../redux/student/student.action";
import { getStudioDetail } from "../../redux/user/user.action";
import { ConfigProvider, Menu } from "antd";
const ChooseStudio = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(["user", "subdomain"]);
  const [studios, setStudios] = useState([]);

  const { user } = useSelector((state) => state.user);

  // const student = useSelector((state) => state.student);
  const { status, subdomain, user_studio } = useSelector(
    (state) => state.studio
  );
  const user_token = user?.token || cookies.user?.token;

  const handleSelectStudio = (e) => {
    const studio_id = e.key.split("_")[0];
    const subdomain = e.key.split("_")[1];
    dispatch(chooseStudio(user_token, studio_id, subdomain));
  };

  useEffect(() => {
    if (user_token) {
      dispatch(getUserRole(user_token, user.studios[0].id));
      dispatch(getStudioDetail(user?.token, user.studios[0].id));
    }
  }, [user_token]);

  const studioActive = useCallback(async () => {
    try {
      const res = await getAllActiveStudio(user?.token);
      if (res.data.data.length === 0) {
        toast.info("Continue Where You Let Off");
        history.push({ pathname: "/register", state: { studio_empty: true } });
      } else {
        setStudios(res.data.data);
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  }, [history, user?.token]);

  useEffect(() => {
    studioActive();
  }, [studioActive]);

  useEffect(() => {
    if (status === "success") {
      setCookie("user", user_studio, {
        path: "/",
        // domain: ".swift.local",
        domain: process.env.REACT_APP_HOST,
      });
      setCookie("subdomain", subdomain, {
        path: "/",
        // domain: ".swift.local",
        domain: process.env.REACT_APP_HOST,
      });

      window.location.href =
        process.env.REACT_APP_PROTOCOL +
        subdomain +
        `.${process.env.REACT_APP_HOST}/${
          user_studio.role === "owner" ? "admin/dashboard" : "dashboard"
        }`;

      dispatch(setStatusDefault());
    }
  }, [dispatch, history, setCookie, status, subdomain, user_studio]);

  const items = useMemo(
    () =>
      studios.map((el) => ({
        key: `${el.id}_${el.subdomain}`,
        label: el.studio_name,
        children:
          el?.roles.length > 1
            ? el.roles.map((role) => ({
                key: `${el.id}_${role}`,
                label: role,
              }))
            : false,
      })),
    [studios]
  );

  return (
    <OrganismLayout withoutHeader withLogout>
      <Col
        lg={4}
        className="mx-auto d-flex align-items-center justify-content-center flex-column p-5"
        style={{ height: "80%" }}
      >
        <img alt="" src={logo} style={{ width: 80 }} />
        <h6 className="my-4">GIGSCLASSROOM</h6>
        <h1 className="heading1 mt-5 fw-bold">
          Welcome,
          {/* <span style={{ color: "#E26851" }}> {user.name.split(" ")[0]}</span> */}
        </h1>
        <p className="regular-text mt-2">
          Which Studio do you want to sign in?
        </p>
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemSelectedColor: "rgba(0, 0, 0, 0.88)",
              },
            },
          }}
        >
          <Menu
            mode="inline"
            items={items}
            className="font-bold capitalize"
            onClick={handleSelectStudio}
          />
        </ConfigProvider>
      </Col>
    </OrganismLayout>
  );
};

export default ChooseStudio;
