import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";

import Button from "../../../../../components/Button/button.component";
import {
  addPlan,
  getClass,
  getDetailPlans,
  setStatusDefault,
  updatePlan,
} from "../../../../../redux/owner/owner.action";
import AtomLoading from "../../../../../components/loding-indicator";
import { toast } from "react-toastify";
import OrganismLayout from "../../../../../components/Layout/organism-layout";
import {
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const listDuration = [
  { label: "Days", value: "days" },
  { label: "Weeks", value: "weeks" },
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];

const ModifyPLan = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const id = props.location.state?.id;
  const isOnboarding = props.location.state?.isOnboarding;

  const [form] = Form.useForm();
  const [cookies] = useCookies(["subdomain", "user"]);

  const {
    addPlanStatus,
    detail_plan,
    isLoading,
    onboarding_status,
    owner_classes,
  } = useSelector((state) => state.owner);
  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const user_token =
    loginFromStudio || isRegisterStudent ? user.token : cookies.user.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user.studio_id
      : cookies.user.studio_id;

  const list_class = owner_classes.data?.length > 0 ? owner_classes.data : [];

  const [classId, setClassId] = useState("");
  const [classData, setClassData] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("all");
  const [selectPlan, setSelectPlan] = useState("membership");

  useEffect(() => {
    if (addPlanStatus === "success") {
      history.push({
        pathname: "/admin/setting-plans",
        state: {
          class_pass: selectPlan === "class pass",
        },
      });
    } else if (addPlanStatus === "success" && isOnboarding) {
      onboarding_status?.term_and_condition === false
        ? history.push({
            pathname: "/admin/setting-terms-and-conditions",
            state: { isOnboarding: true },
          })
        : onboarding_status?.cancellation_setting === false
        ? history.push({
            pathname: "/admin/setting-cancellation",
            state: { isOnboarding: true },
          })
        : onboarding_status?.payment_information === false
        ? history.push({
            pathname: "/admin/setting-payment-gateway",
            state: { isOnboarding: true },
          })
        : history.goBack();
    }
    dispatch(setStatusDefault());
  }, [addPlanStatus, isOnboarding]);

  useEffect(() => {
    if (id) {
      dispatch(getDetailPlans(user_token, studio_id, id));
    }
    dispatch(getClass(user_token, studio_id, 1));
  }, [id, dispatch, studio_id, user_token]);

  useEffect(() => {
    if (Object.keys(detail_plan).length > 0) {
      setClassData(detail_plan?.classes);
      setSelectPlan(detail_plan?.plan_type);
      setSelectedRadio(
        detail_plan?.all_classes_allowed === 1 ? "all" : "specific"
      );
    }
  }, [detail_plan]);

  useEffect(() => {
    if (Object.keys(detail_plan).length > 0) {
      form.setFieldsValue({
        ...detail_plan,
        start_valid_date: dayjs(detail_plan?.start_valid_date),
        end_valid_date: dayjs(detail_plan?.end_valid_date),
      });
    }
  }, [detail_plan, form]);

  const handleSubmit = ({
    notes,
    plan_type,
    plan_name,
    price,
    tax,
    usage_limit,
    start_valid_date,
    end_valid_date,
    time_type,
    valid_for,
    duration_type,
    credits,
  }) => {
    let params = "";

    let finalStartDate = dayjs(start_valid_date).format("YYYY-MM-DD");

    let finalEndDate = dayjs(end_valid_date).format("YYYY-MM-DD");

    let allClass = list_class?.map((item) => item.id);

    if (plan_type === "membership" && time_type === "valid_date") {
      params = {
        plan_type,
        plan_name,
        price: price.toString().replace(/\D/g, ""),
        tax,
        time_type,
        start_valid_date: finalStartDate,
        end_valid_date: finalEndDate,
        usage_limit,
        notes,
        all_classes_allowed: selectedRadio === "all" ? 1 : 0,
        // class_ids: selectedRadio === "all" ? allClass : [classIds],
      };
    } else if (plan_type === "membership" && time_type === "duration") {
      params = {
        plan_type,
        plan_name,
        price: price.toString().replace(/\D/g, ""),
        tax,
        time_type,
        valid_for,
        duration_type,
        usage_limit,
        notes,
        all_classes_allowed: selectedRadio === "all" ? 1 : 0,
        // class_ids: selectedRadio === "all" ? allClass : [classIds],
      };
    } else if (plan_type === "class pass") {
      params = {
        plan_type,
        plan_name,
        price: price.toString().replace(/\D/g, ""),
        tax,
        credits,
        valid_for,
        duration_type,
        usage_limit,
        notes,
        all_classes_allowed: selectedRadio === "all" ? 1 : 0,
        // class_ids: selectedRadio === "all" ? allClass : [classIds],
      };
    }

    if (selectedRadio !== "all") params.class_ids = classData?.map((e) => e.id);

    id
      ? dispatch(updatePlan(params, user_token, studio_id, id))
      : dispatch(addPlan(params, user_token, studio_id));
  };

  const handleAddIdClass = () => {
    if (classId === "" || typeof classId === "undefined") {
      toast.error("Please Choose a Class");
      return;
    } else if (classData.length > 0) {
      if (classData?.find((x) => x.id === classId)) {
        toast.error("Class already exist!");
        return;
      }
    }

    let classes = list_class?.find((e) => e?.id === classId);
    setClassData((prev) => [...prev, classes]);
    setClassId();
  };

  const removeClass = (idx) => {
    let deletedItem = classData[idx];
    const afterDelete = classData.filter((e) => e !== deletedItem);
    setClassData(afterDelete);
  };

  const handleCancel = () => {
    history.push("/admin/setting-plans");
  };

  return (
    <OrganismLayout>
      {isLoading ? (
        <AtomLoading />
      ) : (
        <div className="flex flex-row items-center justify-start min-h-screen">
          <ConfigProvider
            theme={{
              components: {
                Form: {
                  labelFontSize: 16,
                  fontWeightStrong: 600,
                },
              },
            }}
          >
            <Form
              onValuesChange={(value) => {
                if (value.plan_type) {
                  setSelectPlan(value.plan_type);
                }
              }}
              layout="vertical"
              form={form}
              className="md:w-[568px] w-full form-plan"
              onFinish={handleSubmit}
              initialValues={{
                plan_type: "membership",
                tax: 11,
                time_type: "valid_date",
              }}
            >
              <div className="font-semibold text-[2rem] text-start mb-8">
                {id ? "Edit Plan" : "Add Plan"}
              </div>

              <div className="font-medium text-[16px] text-start mb-8">
                Choose a plan
              </div>

              <Form.Item name="plan_type">
                <Radio.Group className="flex justify-between custom-radio">
                  <Radio
                    value="membership"
                    style={{
                      "--active-color": studio_detail?.styles?.header_color,
                    }}
                  >
                    Membership
                  </Radio>
                  <Radio
                    value="class pass"
                    style={{
                      "--active-color": studio_detail?.styles?.header_color,
                    }}
                  >
                    Class pass
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Name"
                name="plan_name"
                className={"font-normal text-[18px]"}
              >
                <Input className="h-10" placeholder="Input plan name" />
              </Form.Item>

              <Form.Item
                label="Price (Exclude Taxes)"
                name="price"
                className={"font-normal text-[18px]"}
              >
                <Input
                  className="h-10"
                  placeholder="Input Price (Exclude Taxes)"
                />
              </Form.Item>

              <Form.Item
                label="Tax (%)"
                name="tax"
                className={"font-normal text-[18px]"}
              >
                <InputNumber size="large" placeholder="Tax" />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.plan_type !== currentValues.plan_type
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("plan_type") === "class pass" ? (
                    <Form.Item
                      label="Add Credits"
                      name="credits"
                      className={"font-normal text-[18px]"}
                    >
                      <InputNumber size="large" placeholder="Add Credits.." />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.plan_type !== currentValues.plan_type
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("plan_type") === "membership" ? (
                    <Form.Item
                      name="time_type"
                      className={"font-normal text-[18px]"}
                    >
                      <Radio.Group className="flex justify-between custom-radio">
                        <Radio
                          value="valid_date"
                          style={{
                            "--active-color":
                              studio_detail?.styles?.header_color,
                          }}
                        >
                          Valid Date
                        </Radio>
                        <Radio
                          value="duration"
                          style={{
                            "--active-color":
                              studio_detail?.styles?.header_color,
                          }}
                        >
                          Duration
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {selectPlan === "class pass" ? (
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.time_type !== currentValues.time_type
                  }
                >
                  <Space.Compact className="w-full gap-4">
                    <Form.Item
                      label="Valid for"
                      name="valid_for"
                      className="w-full"
                    >
                      <InputNumber
                        className="h-10 w-full"
                        placeholder="Input Valid For Month"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Duration"
                      name="duration_type"
                      className="w-full"
                    >
                      <Select
                        options={listDuration}
                        className="h-10 w-full"
                        placeholder="Select Month"
                      />
                    </Form.Item>
                  </Space.Compact>
                </Form.Item>
              ) : (
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.time_type !== currentValues.time_type
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue("time_type") === "duration" ? (
                      <Space.Compact className="w-full gap-4">
                        <Form.Item
                          label="Valid for"
                          name="valid_for"
                          className="w-full"
                        >
                          <InputNumber
                            className="h-10 w-full"
                            placeholder="Input Valid For Month"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Duration"
                          name="duration_type"
                          className="w-full"
                        >
                          <Select
                            options={listDuration}
                            className="h-10 w-full"
                            placeholder="Select Month"
                          />
                        </Form.Item>
                      </Space.Compact>
                    ) : (
                      <Space.Compact className="w-full gap-4">
                        <Form.Item
                          label="Start Valid Date"
                          name="start_valid_date"
                          className="w-full"
                        >
                          <DatePicker size="large" className="w-full" />
                        </Form.Item>

                        <Form.Item
                          label="End Valid Date"
                          name="end_valid_date"
                          className="w-full"
                        >
                          <DatePicker size="large" className="w-full" />
                        </Form.Item>
                      </Space.Compact>
                    )
                  }
                </Form.Item>
              )}

              {selectPlan === "membership" ? (
                <Form.Item label="Usage Limit" name="usage_limit">
                  <InputNumber size="large" placeholder="100" />
                </Form.Item>
              ) : (
                <></>
              )}

              <Form.Item label="Notes" name="notes">
                <Input.TextArea
                  rows={5}
                  className="h-10 w-full"
                  placeholder="Add Notes..."
                />
              </Form.Item>

              <Form.Item label={"Eligible Class"} name="eligible_class">
                <Radio.Group
                  className={"flex flex-col custom-radio "}
                  defaultValue={selectedRadio}
                  onChange={(e) => setSelectedRadio(e.target.value)}
                  value={selectedRadio}
                >
                  <div className="flex p-2 rounded">
                    <div className="flex items-center h-5">
                      <Radio
                        id="helper-radio-4"
                        value={"all"}
                        className="w-4 h-4"
                        style={{
                          "--active-color": studio_detail?.styles?.header_color,
                        }}
                      ></Radio>
                    </div>

                    <div className="ms-2 text-sm">
                      <div className="font-medium">
                        <label htmlFor="helper-radio-4">All Classes</label>
                        <p
                          id="helper-radio-text-4"
                          className="text-xs font-normal text-[#475569] "
                        >
                          Make this plan available to all classes and all
                          classes in the future
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex p-2 rounded">
                    <div className="flex items-center h-5">
                      <Radio
                        id="helper-radio-5"
                        className="w-4 h-4"
                        value={"specific"}
                        style={{
                          "--active-color": studio_detail?.styles?.header_color,
                        }}
                      ></Radio>
                    </div>

                    <div className="ms-2 text-sm w-full">
                      <div className="font-medium w-full flex flex-col gap-3">
                        <label htmlFor="helper-radio-5">Specific Classes</label>
                        <div className={"flex flex-col gap-3 items-start"}>
                          <label htmlFor={"select-class"}>Choose Classes</label>
                          <Select
                            size={"large"}
                            disabled={selectedRadio !== "specific"}
                            id={"select-class"}
                            value={classId}
                            onSelect={(e) => setClassId(e)}
                            options={
                              owner_classes?.data?.map((item) => ({
                                label: item.class_name,
                                value: item.id,
                              })) || []
                            }
                          />
                          <Button
                            type="primary"
                            title="Add"
                            onClick={handleAddIdClass}
                            isLoading={selectedRadio !== "specific"}
                            style={{
                              backgroundColor:
                                studio_detail?.styles?.header_color,
                            }}
                          />
                        </div>

                        <label>Classes Chosen</label>
                        {classData?.length > 0 &&
                          classData?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  "w-full flex flex-row justify-between items-center align-middle"
                                }
                              >
                                <p>{item?.class_name}</p>
                                <p>-</p>
                                <CloseCircleOutlined
                                  className={
                                    "hover:-translate-y-1 hover:scale-110 hover:text-red-800 duration-300 cursor-pointer"
                                  }
                                  onClick={() => removeClass(index)}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Radio.Group>
              </Form.Item>

              <div className={"flex flex-row justify-between"}>
                <Form.Item>
                  <Button
                    buttonType="submit"
                    type="primary"
                    title="CONTINUE"
                    style={{
                      backgroundColor: studio_detail?.styles?.header_color,
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    buttonType="button"
                    type="secondary"
                    title="Cancel"
                    onClick={() => handleCancel()}
                  />
                </Form.Item>
              </div>
            </Form>
          </ConfigProvider>
        </div>
      )}
    </OrganismLayout>
  );
};

export default ModifyPLan;
