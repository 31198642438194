import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

// Admin
import BankList from "../containers/Bank/index";
import ModifyBank from "../containers/Bank/modify-bank";

// Owner
import Login from "../containers/Login/login.component";
import StudioLogin from "../containers/StudioLogin/studio-login.component";
import Register from "../containers/RegisterStudio/register.component";
import RegisterStudent from "../containers/RegisterStudent/index";
import WelcomeOwner from "../containers/Owner/welcome.component";
import AddClassOwner from "../containers/Owner/basic-settings-component";
import VerifyOtp from "../containers/Login/verify-otp.component";
import ChooseStudio from "../containers/Login/choose-studio.component";
import Dashboard from "../containers/Studio/Owner/Dashboard/dashboard.component";
import TeacherDashBoard from "../containers/Studio/Owner/Dashboard/dashboard.component.teacher";
import { Students } from "../containers/Studio/Owner/Students/students.component";
import { Classes } from "../containers/Studio/Owner/Classes/classes.component";
import { AddClass } from "../containers/Studio/Owner/Classes/addClass/add-class.component";
import GeneralTerms from "../containers/RegisterStudent/general-terms";
import { EditClass } from "../containers/Studio/Owner/Classes/EditClass/index";
import DetailClass from "../containers/Studio/Owner/Classes/detail";
import { Teachers } from "../containers/Studio/Owner/Teachers";
import AddTeachers from "../containers/Studio/Owner/Teachers/modify";
import TeacherDetail from "../containers/Studio/Owner/Teachers/detail";
import DetailStudent from "../containers/Studio/Owner/Students/detail";
import { AddNotes } from "../containers/Studio/Owner/Students/add-notes";
// import Settings from "../containers/Studio/Owner/Settings";
import { TermsAndConditions } from "../containers/Studio/Owner/Settings/terms-and-conditions";
import { Cancellation } from "../containers/Studio/Owner/Settings/cancellation";
import SettingPayment from "../containers/Studio/Owner/Settings/payment";
import { MidtransConfiguration } from "../containers/Studio/Owner/Settings/MidtransConfiguration";
import EditProfile from "../containers/Studio/Owner/Profile/edit-profile";
import SettingPlans from "../containers/Studio/Owner/Settings/Plans/plans";
import DetailPlan from "../containers/Studio/Owner/Settings/Plans/detail-plan";
import ModifyPLan from "../containers/Studio/Owner/Settings/Plans/modify-plans";
import VerifyEmailOwner from "../containers/Studio/Owner/Profile/verify-email";
import ChangeEmailOwner from "../containers/Studio/Owner/Profile/change-email";
import ListStatistic from "../containers/Studio/Owner/Dashboard/list-statistic";
import SessionDetail from "../containers/Studio/Owner/Classes/session-detail";
import IncomeReport from "../containers/Studio/Owner/Settings/income-report";
import ModifyStudent from "../containers/Studio/Owner/Students";
import ChangeHeader from "../containers/Studio/Owner/Settings/change-header";
import Property from "../containers/Studio/Owner/Settings/property";
import Category from "../containers/Studio/Owner/Rooms/category";

// Students
import PlanList from "../containers/Studio/Students/students-plan/index";
import ClassList from "../containers/Studio/Students/students-class/index";
import StudentDashboard from "../containers/Studio/Students/dashboard/index";
import CancelClass from "../containers/Studio/Students/dashboard/cancel-class";
import CancelClassComplete from "../containers/Studio/Students/dashboard/cancel-class-complete";
import StudentProfile from "../containers/Studio/Students/students-profile/edit-profile";
import VerifyEmail from "../containers/Studio/Students/students-profile/verify-email";
import ChangeEmail from "../containers/Studio/Students/students-profile/change-email";
import PaymentClass from "../containers/Studio/Students/students-class/payment";
import ChoosePayment from "../containers/Studio/Students/students-payment/choose-payment";
import BankTransfer from "../containers/Studio/Students/students-payment/bank-transfer";
import WaitingPayment from "../containers/Studio/Students/students-payment/waiting-payment";
import PaymentVerification from "../containers/Studio/Students/students-payment/payment-verification";
import PaymentProcessed from "../containers/Studio/Students/students-payment/transfer-success";
import DetailTransaction from "../containers/Studio/Students/students-plan/detail-transaction";
import BuyPlans from "../containers/Studio/Students/students-plan/offering-plans";
import MidtransCallbackSucces from "../containers/Studio/Students/students-payment/midtrans-success";
import ConfirmClass from "../containers/Studio/Students/students-class/confirm-class";
import DashboardClassDetail from "../containers/Studio/Owner/Dashboard/class-detail";
import PublicClassList from "../containers/Studio/Students/students-class/public-class-list";
import Locations from "../containers/Studio/Owner/Settings/locations";
import PrivacyPolicy from "../containers/privacy-policy";
import TermsAndConditionsPage from "../containers/terms-condition";
import Balance from "../containers/Studio/Owner/Balance/balance";
import DetailBalance from "../containers/Studio/Owner/Balance/detail";
import Amenities from "../containers/Studio/Owner/Rooms/amenities";
import RoomLists from "../containers/Studio/Owner/Rooms/lists";
import Extras from "../containers/Studio/Owner/Rooms/extras";
import ModifyExtras from "../containers/Studio/Owner/Rooms/modify-extras";

const admin_routes = [
  {
    path: "/banks",
    component: BankList,
    exact: true,
  },
  {
    path: "/bank/add",
    component: ModifyBank,
    exact: true,
  },
  {
    path: "/bank/edit/:id",
    component: ModifyBank,
    exact: true,
  },
];

const routes = [
  {
    path: "/choose-studio",
    component: ChooseStudio,
    exact: true,
  },
];

const studio_routes = [
  {
    path: "/admin/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/admin/list-statistic",
    component: ListStatistic,
    exact: true,
  },
  {
    path: "/admin/onboarding",
    component: WelcomeOwner,
    exact: true,
  },
  {
    path: "/admin/basic-settings",
    component: AddClassOwner,
    exact: true,
  },
  {
    path: "/admin/students",
    component: Students,
    exact: true,
  },
  {
    path: "/admin/add-student",
    component: ModifyStudent,
    exact: true,
  },
  {
    path: "/admin/student/:id/detail",
    component: DetailStudent,
    exact: true,
  },
  {
    path: "/admin/student/:id/edit",
    component: ModifyStudent,
    exact: true,
  },
  {
    path: "/admin/add/notes",
    component: AddNotes,
    exact: true,
  },
  {
    path: "/admin/edit/notes",
    component: AddNotes,
    exact: true,
  },
  // {
  //   path: "/admin/settings",
  //   component: Settings,
  //   exact: true,
  // },
  {
    path: "/admin/settings/setting-general-terms",
    component: TermsAndConditions,
    exact: true,
  },
  {
    path: "/admin/setting-plans",
    component: SettingPlans,
    exact: true,
  },
  {
    path: "/admin/settings/setting-payment-gateway",
    component: SettingPayment,
    exact: true,
  },
  {
    path: "/admin/midtrans-configuration",
    component: MidtransConfiguration,
    exact: true,
  },
  {
    path: "/admin/settings/setting-cancellation",
    component: Cancellation,
    exact: true,
  },
  {
    path: "/admin/settings/setting-background",
    component: ChangeHeader,
    exact: true,
  },
  {
    path: "/admin/settings/setting-locations",
    component: Locations,
    exact: true,
  },
  {
    path: "/admin/settings/property",
    component: Property,
    exact: true,
  },
  {
    path: "/admin/add-plan",
    component: ModifyPLan,
    exact: true,
  },
  {
    path: "/admin/edit-plan",
    component: ModifyPLan,
    exact: true,
  },
  {
    path: "/admin/detail-plan",
    component: DetailPlan,
    exact: true,
  },
  {
    path: "/admin/report",
    component: IncomeReport,
    exact: true,
  },
  {
    path: "/admin/balances",
    component: Balance,
    exact: true,
  },
  {
    path: "/admin/balance/:id/detail",
    component: DetailBalance,
    exact: true,
  },
  {
    path: "/admin/classes",
    component: Classes,
    exact: true,
  },
  {
    path: "/admin/add-class",
    component: AddClass,
    exact: true,
  },
  {
    path: "/admin/class/:id/detail",
    component: DetailClass,
    exact: true,
  },
  {
    path: "/admin/session/:id/detail",
    component: SessionDetail,
    exact: true,
  },
  {
    path: "/admin/class/:id/edit",
    component: AddClass,
    exact: true,
  },
  {
    path: "/admin/teachers",
    component: Teachers,
    exact: true,
  },
  {
    path: "/admin/add-teacher",
    component: AddTeachers,
    exact: true,
  },
  {
    path: "/admin/teacher/:id/edit",
    component: AddTeachers,
    exact: true,
  },
  {
    path: "/admin/teacher/:id/detail",
    component: TeacherDetail,
    exact: true,
  },
  {
    path: "/admin/profile",
    component: EditProfile,
    exact: true,
  },
  {
    path: "/admin/change-email",
    component: ChangeEmailOwner,
    exact: true,
  },
  {
    path: "/admin/verify-email",
    component: VerifyEmailOwner,
    exact: true,
  },
  {
    path: "/admin/buy-plan",
    component: BuyPlans,
    exact: true,
  },
  {
    path: "/admin/class-list",
    component: ClassList,
    exact: true,
  },
  {
    path: "/admin/online-payment-success",
    component: MidtransCallbackSucces,
    exact: true,
  },
  {
    path: "/admin/payment-class/:id/detail",
    component: PaymentClass,
    exact: true,
  },
  {
    path: "/admin/confirm-class",
    component: ConfirmClass,
    exact: true,
  },
  {
    path: "/admin/rooms/list",
    component: RoomLists,
    exact: true,
  },
  {
    path: "/admin/rooms/category",
    component: Category,
    exact: true,
  },
  {
    path: "/admin/rooms/amenities",
    component: Amenities,
    exact: true,
  },
  {
    path: "/admin/rooms/extras",
    component: Extras,
    exact: true,
  },
  {
    path: "/admin/rooms/modify-extras/add",
    component: ModifyExtras,
    exact: true,
  },
  {
    path: "/admin/rooms/modify-extras/edit/:id",
    component: ModifyExtras,
    exact: true,
  },
];

const teacher_routes = [
  {
    path: "/admin/dashboard",
    component: TeacherDashBoard,
    exact: true,
  },
  {
    path: "/admin/dashboard-class-detail",
    component: DashboardClassDetail,
    exact: true,
  },
  {
    path: "/admin/students",
    component: Students,
    exact: true,
  },
  {
    path: "/admin/add-student",
    component: ModifyStudent,
    exact: true,
  },
  {
    path: "/admin/student/:id/detail",
    component: DetailStudent,
    exact: true,
  },
  {
    path: "/admin/student/:id/edit",
    component: ModifyStudent,
    exact: true,
  },
  {
    path: "/admin/add-notes",
    component: AddNotes,
    exact: true,
  },
  {
    path: "/admin/classes",
    component: Classes,
    exact: true,
  },
  {
    path: "/admin/add-class",
    component: AddClass,
    exact: true,
  },
  {
    path: "/admin/class/:id/detail",
    component: DetailClass,
    exact: true,
  },
  {
    path: "/admin/class/:id/edit",
    component: AddClass,
    exact: true,
  },
  {
    path: "/admin/profile",
    component: EditProfile,
    exact: true,
  },
  {
    path: "/admin/change-email",
    component: ChangeEmailOwner,
    exact: true,
  },
  {
    path: "/admin/verify-email",
    component: VerifyEmailOwner,
    exact: true,
  },
  {
    path: "/admin/buy-plan",
    component: BuyPlans,
    exact: true,
  },
  {
    path: "/admin/class-list",
    component: ClassList,
    exact: true,
  },
  {
    path: "/admin/online-payment-success",
    component: MidtransCallbackSucces,
    exact: true,
  },
  {
    path: "/admin/payment-class/:id/detail",
    component: PaymentClass,
    exact: true,
  },
  {
    path: "/admin/confirm-class",
    component: ConfirmClass,
    exact: true,
  },
];

const student_routes = [
  {
    path: "/dashboard",
    component: StudentDashboard,
    exact: true,
  },
  {
    path: "/cancel-class",
    component: CancelClass,
    exact: true,
  },
  {
    path: "/cancel-class-complete",
    component: CancelClassComplete,
    exact: true,
  },
  {
    path: "/plan-list",
    component: PlanList,
    exact: true,
  },
  {
    path: "/plan-transaction/:id/detail",
    component: DetailTransaction,
    exact: true,
  },
  {
    path: "/buy-plan",
    component: BuyPlans,
    exact: true,
  },
  {
    path: "/online-payment-success",
    component: MidtransCallbackSucces,
    exact: true,
  },
  {
    path: "/class-list",
    component: ClassList,
    exact: true,
  },
  {
    path: "/payment-class/:id/detail",
    component: PaymentClass,
    exact: true,
  },
  {
    path: "/confirm-class",
    component: ConfirmClass,
    exact: true,
  },
  {
    path: "/choose-payment",
    component: ChoosePayment,
    exact: true,
  },
  {
    path: "/bank-transfer",
    component: BankTransfer,
    exact: true,
  },
  {
    path: "/waiting-payment",
    component: WaitingPayment,
    exact: true,
  },
  {
    path: "/payment-verification",
    component: PaymentVerification,
    exact: true,
  },
  {
    path: "/payment-processed",
    component: PaymentProcessed,
    exact: true,
  },
  {
    path: "/student-profile",
    component: StudentProfile,
    exact: true,
  },
  {
    path: "/change-email",
    component: ChangeEmail,
    exact: true,
  },
  {
    path: "/verify-email",
    component: VerifyEmail,
    exact: true,
  },
];

const WithAuthenRoute = ({ component: Component, user }) => {
  return (
    <Route
      exact
      render={(props) => {
        if (user.token) {
          return <Component {...props} />;
        }

        return <Redirect to="/login" />;
      }}
    />
  );
};

const StudioRoute = ({
  component: Component,
  cookies,
  loginFromStudio,
  isRegisterStudent,
}) => {
  const user_token =
    isRegisterStudent || loginFromStudio ? cookies?.token : cookies.user?.token;

  return (
    <Route
      exact
      render={(props) => {
        if (user_token) {
          return <Component {...props} />;
        }

        return <Redirect to="/admin/login" />;
      }}
    />
  );
};

const renderAuthenticatedRoute = (user) => {
  if (user?.role === "superadmin") {
    return admin_routes.map(({ path, component }) => (
      <WithAuthenRoute
        key={path}
        path={path}
        component={component}
        user={user}
      />
    ));
  } else {
    return routes.map(({ path, component }) => (
      <WithAuthenRoute
        key={path}
        path={path}
        component={component}
        user={user}
      />
    ));
  }
};

const renderStudioRoute = (cookies, loginFromStudio, isRegisterStudent) => {
  if (cookies?.role === "owner" || cookies.user?.role === "owner") {
    return studio_routes.map(({ path, component }) => (
      <StudioRoute
        key={path}
        path={path}
        component={component}
        cookies={cookies}
        loginFromStudio={loginFromStudio}
        isRegisterStudent={isRegisterStudent}
      />
    ));
  } else if (cookies?.role === "teacher" || cookies.user?.role === "teacher") {
    return teacher_routes.map(({ path, component }) => (
      <StudioRoute
        key={path}
        path={path}
        component={component}
        cookies={cookies}
        loginFromStudio={loginFromStudio}
        isRegisterStudent={isRegisterStudent}
      />
    ));
  } else if (cookies?.role === "student" || cookies.user?.role === "student") {
    return student_routes.map(({ path, component }) => (
      <StudioRoute
        key={path}
        path={path}
        component={component}
        cookies={cookies}
        loginFromStudio={loginFromStudio}
        isRegisterStudent={isRegisterStudent}
      />
    ));
  }

  return <Redirect to="/login" />;
};

const Routes = () => {
  const [cookies] = useCookies(["subdomain", "user"]);
  const { user, loginFromStudio } = useSelector((state) => state.user);

  const { user_studio, isRegisterStudent } = useSelector(
    (state) => state.studio
  );

  const subdomain = window.location.host.split(".")[0];

  const dataUser = loginFromStudio
    ? user
    : isRegisterStudent
    ? user_studio
    : cookies;

  const redirectUrl = dataUser.role === "student" ? "/login" : "/admin/login";

  const redirect =
    loginFromStudio || isRegisterStudent ? redirectUrl : "/login";

  return (
    <Router>
      <Switch>
        {subdomain === process.env.REACT_APP_HOST.split(".")[0] ? (
          <Route exact key="/login" path="/login" component={Login} />
        ) : (
          <Route
            exact
            key="/admin/login"
            path="/admin/login"
            component={StudioLogin}
          />
        )}

        <Route exact key="/login" path="/login" component={StudioLogin} />

        <Route
          exact
          key="/privacy-policy"
          path="/privacy-policy"
          component={PrivacyPolicy}
        />

        <Route
          exact
          key="/terms-and-conditions"
          path="/terms-and-conditions"
          component={TermsAndConditionsPage}
        />

        {subdomain === process.env.REACT_APP_HOST.split(".")[0] ? (
          <Route exact key="/register" path="/register" component={Register} />
        ) : (
          <Route
            exact
            key="/register-student"
            path="/register-student"
            component={RegisterStudent}
          />
        )}

        <Route
          exact
          key="/general-terms"
          path="/general-terms"
          component={GeneralTerms}
        />

        <Route
          exact
          key="/public-class-list"
          path="/public-class-list"
          component={PublicClassList}
        />

        <Route
          exact
          key="/public-offering-plans"
          path="/public-offering-plans"
          component={BuyPlans}
        />

        <Route
          exact
          key="/verify"
          path="/verify"
          component={VerifyOtp}
          // render={(props) => {
          //   if (login_email !== "") {
          //     return <VerifyOtp {...props} />;
          //   }

          //   return <Redirect to={redirect} />;
          // }}
        />
        {subdomain === process.env.REACT_APP_HOST.split(".")[0]
          ? renderAuthenticatedRoute(user)
          : renderStudioRoute(dataUser, loginFromStudio, isRegisterStudent)}

        <Route
          exact
          path="*"
          render={() =>
            subdomain === process.env.REACT_APP_HOST.split(".")[0] ? (
              <Redirect to={redirect} />
            ) : (
              <Redirect
                to={`${
                  dataUser.role === "student"
                    ? "/dashboard"
                    : "/admin/dashboard"
                }`}
              />
            )
          }
        />
      </Switch>
    </Router>
  );
};

export default Routes;
