import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "./owner.styles.scss";

import AddStudent from "./add-student-component";
import AddTeacher from "./add-teacher-component";
import AddSchedule from "./add-schedule-component";
import AddPlan from "./add-plan-component";

import SettingPayment from "./payment";
import { Cancellation } from "./cancellation";
import { TermsAndConditions } from "./terms-and-conditions";
import { useCookies } from "react-cookie";
import {
  getOnboardingStatus,
  getTeacher,
} from "../../redux/owner/owner.action";
import { Steps } from "antd";
import AddLocations from "./add-locations-component";
import { getStudioDetail } from "../../redux/user/user.action";
import hexToRGBA from "../../helper/hexToRbga";

const steps = [
  {
    title: "Add student",
  },
  {
    title: "Add teacher",
  },
  {
    title: "Add locations",
  },
  {
    title: "Add class",
  },
  {
    title: "Add plan",
  },
];

const stepNext = [
  { title: "Insert general terms" },
  { title: "Set Cancellation Settings" },
  { title: "Setup payment gateway" },
];

const AddClass = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [selectedStep, setSelectedStep] = useState(1);
  const [themeColor, setThemeColor] = useState("");
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const [cookies, setCookies] = useCookies(["user"]);

  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );
  const { isRegisterStudent } = useSelector((state) => state.studio);

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;

  const nextStep = () => {
    setStep(step + 1);
  };

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const nextSelectedStep = () => {
    setSelectedStep(2);
  };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const renderSteps = () => {
    switch (step) {
      case 1:
        return <AddStudent colorTheme={themeColor} nextStep={nextStep} />;
      case 2:
        return (
          <AddTeacher
            colorTheme={themeColor}
            nextStep={nextStep}
            setSelectedStep={setStep}
          />
        );
      case 3:
        return <AddLocations colorTheme={themeColor} nextStep={nextStep} />;
      case 4:
        return (
          <AddSchedule
            colorTheme={themeColor}
            nextStep={nextStep}
            setSelectedStep={setStep}
          />
        );
      case 5:
        return (
          <AddPlan
            nextStep={nextStep}
            setSelectedStep={nextSelectedStep}
            colorTheme={themeColor}
          />
        );
      case 6:
        return (
          <TermsAndConditions nextStep={nextStep} colorTheme={themeColor} />
        );
      case 7:
        return <Cancellation nextStep={nextStep} colorTheme={themeColor} />;
      case 8:
        return <SettingPayment nextStep={nextStep} colorTheme={themeColor} />;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getOnboardingStatus(user_token, studio_id));
    dispatch(getTeacher(user_token, studio_id, 1));
    dispatch(getStudioDetail(user_token, studio_id));
  }, [step]);

  useEffect(() => {
    setThemeColor(studio_detail?.styles?.header_color);
  }, [studio_detail]);

  return (
    <div className="flex md:flex-row flex-col min-h-screen">
      <div
        style={{ backgroundColor: hexToRGBA(themeColor, 0.1) }}
        className="w-full md:w-[475px] flex flex-col  items-center"
      >
        {step <= 5 ? (
          <>
            <p className="text-[#0F172A] text-xl font-semibold mt-[187px] px-3">
              Basic steps to complete your studio:
            </p>
            <div className="h-[470px] mt-[52px]">
              <Steps
                className="h-full"
                direction="vertical"
                items={steps}
                current={step - 1}
                responsive={true}
              />
            </div>
          </>
        ) : (
          <>
            <p className="text-[#0F172A] text-xl font-semibold mt-[187px] px-3">
              Next steps setup to complete your studio:
            </p>
            <div className="h-[470px] mt-[52px]">
              <Steps
                className="h-full"
                direction="vertical"
                items={stepNext}
                current={step - 6}
                responsive={true}
              />
            </div>
          </>
        )}
      </div>
      <div className="w-full">{renderSteps()}</div>

      {/* <Footer /> */}
    </div>
  );
};

export default AddClass;
