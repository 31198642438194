import { Nav } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/user/user.action";
import "./header.styles.scss";
import { Space } from "antd";
import logo from "./../../assets/images/gigs-logo.svg";
import React, {useEffect, useState} from "react";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Button } from "antd";

const Header = ({
  title,
  withLogout,
  isBrowseClasses,
  mainPage,
  colorHeader,
  withoutHeader,
  studioDetail,
  withMenuLinks,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [cookies, setCookies, removeCookies] = useCookies(["user"]);

  const { user, studio_detail } = useSelector((state) => state.user);

  const studioLogo = studioDetail?.studio_logo_link ?? studio_detail?.logo_link;
  const studioName = studioDetail?.studio_name ?? studio_detail?.studio_name;

  const handleLogout = () => {
    if (cookies.user) {
      removeCookies("user", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
      removeCookies("subdomain", {
        path: "/",
        domain: process.env.REACT_APP_HOST,
        // domain: ".swift.local",
      });
    }
    dispatch(logout(user?.token));
    history.push("/login");
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div>
        <div
          style={{ backgroundColor: colorHeader }}
          className={`flex items-center justify-between px-4 py-2 ${withMenuLinks && withMenuLinks.length > 0 ? "shadow-bottom" : ""}`}
        >
          <div className="flex align-items-center">
              { withMenuLinks && withMenuLinks.length > 0 ? (
                  <>
                      <Drawer
                          title={
                              <Space className="ml-[28px]">
                                  <img alt="" src={withoutHeader ? logo : studioLogo} width={64} />
                                  <h2 className="text-white font-black text-xl mb-0">
                                      {withoutHeader ? "GiGSCLASSROOM" : studioName}
                                  </h2>
                              </Space>
                          }
                          closeIcon={
                              <div className="w-6 h-6 flex items-center justify-center bg-transparent">
                                  <div className="relative" style={{ width: "24px", height: "24px" }}>
                                      <div className="absolute top-[8px] w-full h-0.5 bg-white rotate-45"></div>
                                      <div className="absolute top-[8px] w-full h-0.5 bg-white -rotate-45"></div>
                                  </div>
                              </div>
                          }
                          placement="left"
                          closable={true}
                          onClose={() => setIsOpen(false)}
                          open={isOpen}
                          style={{ backgroundColor: colorHeader }}
                          headerStyle={{ padding: "8px 24px"  }}
                          className="text-white"
                      >
                          <div className="flex flex-column border-bottom border-2 border-white">
                              {withMenuLinks.map((link, index) => (
                                  <span
                                      onClick={() => history.push(link.url)}
                                      style={{marginRight: 80}}
                                      className="cursor-pointer hover:opacity-50 text-white font-semibold text-[16px] md:text-[20px] mb-[24px]"
                                  >{link.name}</span>
                              ))}
                          </div>
                          <Nav.Link
                              style={{
                                  width: 120,
                                  color: colorHeader,
                                  fontSize: 16,
                                  fontWeight: 700,
                              }}
                              className="bg-white text-center d-block d-md-none mt-[40px]"
                              href="/login"
                          >Login</Nav.Link>
                      </Drawer>

                      <Button
                      className="md:hidden p-2 rounded bg-transparent border-transparent !border-transparent outline-none focus:!outline-none hover:!outline-none hover:!border-transparent active:!outline-none active:!border-transparent focus:!border-transparent mr-[28px]"
                      onClick={() => setIsOpen(true)}
                      >
                      <div className="d-flex flex-column justify-content-between" style={{ width: "24px", height: "18px" }}>
                      <div className="bg-white rounded" style={{ width: "100%", height: "2px" }}></div>
                      <div className="bg-white rounded" style={{ width: "100%", height: "2px" }}></div>
                      <div className="bg-white rounded" style={{ width: "100%", height: "2px" }}></div>
                      </div>
                      </Button>
                  </>
                ):(
                    <></>
                  )
              }

              <Space onClick={() => history.push("/")} className={"cursor-pointer"}>
                  <img alt="" src={withoutHeader ? logo : studioLogo} width={64} />
                  <h2 className="text-white font-black text-xl mb-0">
                      {withoutHeader ? "GiGSCLASSROOM" : studioName}
                  </h2>
              </Space>

              { withMenuLinks && withMenuLinks.length > 0 ? (
                  <div
                      style={{marginLeft: 80}}
                      className="hidden md:flex justify-content-start"
                  >
                      {withMenuLinks.map((link, index) => (
                          <span
                              onClick={() => history.push(link.url)}
                              style={{marginRight: 80}}
                              className="text-center cursor-pointer hover:opacity-50 text-white"
                          >{link.name}</span>
                      ))}
                  </div>
              ):(<></>)
              }
          </div>

          {withLogout ? (
            <span
              style={{
                width: 120,
                color: colorHeader,
                fontSize: 16,
                fontWeight: 700,
              }}
              className="bg-white cursor-pointer text-center py-2"
              onClick={() => {
                handleLogout();
              }}
            >
              Log Out
            </span>
          ) : isBrowseClasses ? (
            <Nav.Link
              style={{
                width: 120,
                color: colorHeader,
                fontSize: 16,
                fontWeight: 700,
              }}
              className="bg-white text-center d-none d-md-block"
              href="/login"
            >
              Login
            </Nav.Link>
          ) : (
            <div style={{ width: "70px" }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
