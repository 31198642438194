import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ConfigProvider, Table, Modal, Tag, Space, Select, Drawer } from "antd";

import calender from "../../../../assets/images/calender.svg";
//import thumbnail from "../../../../assets/images/class-thumbnail.png";
import thumbnail from "../../../../assets/images/teacher.svg";
import filters_icon from "../../../../assets/images/filters.svg";
import more_icon from "../../../../assets/images/more-vertical.svg";

import { DateRange } from "react-date-range";
import "./../students.styles.scss";
import {
  getListOfferingClasses,
  getMyActivePlan,
  getPublicClasses,
  getPublicListOfferingClasses,
  getPublicStudioLocation,
} from "../../../../redux/student/student.action";
import "react-calendar/dist/Calendar.css";

import EmptyData from "../../../../components/no-data";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import hexToRGBA from "../../../../helper/hexToRbga";
import Button from "../../../../components/Button/button.component";
import { CaretDownOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { getStudioInfo } from "../../../../services/studios";
import * as authService from "../../../../services/auth";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import {
  getStudioDetail,
  requestOtp,
  setLoginFromStudio,
  setNewUser,
  setStatusVerifyDefault,
} from "../../../../redux/user/user.action";
import { ModalLogin } from "../../../StudioLogin/modal";
import StudioLoginForm from "../../../StudioLogin/login-form";
import FormOTP from "../../../../components/OTP";
import ModalDetailClass from "./modal-detail-class";
import ModalSuccessBookingClass from "../../../../components/Modal/success-booking-class";
import { sendGetRequest } from "../../../../services/students";
import { getDisbursementSetting } from "../../../../services/payment";
import { capitalize } from "../../../../helper/truncateString";
import logo from "../../../../assets/images/gigs-logo.svg";
//import {Nav} from "react-bootstrap/Nav";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const PublicClassList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const classIds = window.location.search.split("=")[1];

  const currentDate = new Date();
  let last = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

  const [propsPayment, setPropsPayment] = useState();
  const [isModalOpenDateRange, setIsModalDateOpeRange] = useState(false);
  const [isModalOpenDetailClass, setIsModalOpenDetailClass] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [className, setClassName] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [locationFilterId, setLocationFilterId] = useState(null);
  const [classCategoryId, setClassCategoryId] = useState(null);
  const [classCategories, setClassCatogories] = useState([]);
  const [studioClasses, setStudioClasses] = useState([]);
  const [studioLocation, setStudioLocation] = useState([]);
  const [selectedClassDetail, setSelectedClassDetail] = useState({});
  const [state, setState] = useState([
    {
      startDate: currentDate,
      endDate: last,
      key: "selection",
    },
  ]);
  const [colorTheme, setColorTheme] = useState("");
  const [colorThemeOpacity, setColorThemeOpacity] = useState("");
  const [email, setEmail] = useState("");
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [registerWithSocmed, setRegisterWithSocmed] = useState(false);
  const [prosessEndroll, setProsessEndroll] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [method, setMethod] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [studio_detail, setstudio_detail] = useState({ id: "" });

  const [cookies] = useCookies();

  const mobileSize = useMemo(() => window.screen.width < 576, []);

  const { public_classes, class_list, isLoadingClasses, active_plan } =
    useSelector((state) => state.student);
  const { user, statusVerify } = useSelector((state) => state.user);

  const isSettlement = propsPayment?.payment?.payment_status === "settlement";

  const user_token = user?.token || cookies.user?.token;
  const studio_id = user?.studio_id || cookies.user?.studio_id;
  const subdomain =
    process.env.NODE_ENV === "development"
      ? "vedic-staging2"
      : window.location.host.split(".")[0];
  const paramWP = authService.paramsWP();
  const class_offerings = user_token ? class_list : public_classes;

  const redirectToLogin = () => {
    setShowModalLogin(true);
  };

  useEffect(() => {
    const params = new URLSearchParams();

    params.append(
      "start_date",
      moment(state[0].startDate).format("YYYY-MM-DD")
    );
    params.append("end_date", moment(state[0].endDate).format("YYYY-MM-DD"));

    if (className) {
      params.append("class_name", className);
    }

    if (locationFilterId) {
      params.append("location_id", locationFilterId);
    }

    if (classCategoryId) {
      params.append("category_ids", classCategoryId);
    }

    if (user_token && studio_id) {
      dispatch(getListOfferingClasses(user_token, studio_id, params));
      dispatch(getMyActivePlan(user_token, studio_id));
    } else {
      dispatch(getPublicListOfferingClasses(subdomain, params.toString()));
    }
  }, [
    className,
    dispatch,
    locationFilterId,
    state,
    studio_id,
    subdomain,
    user_token,
    isSettlement,
    classCategoryId,
  ]);

  useEffect(() => {
    handleGetStudioInfo();
    handleGetPublicClasses();
  }, []);

  useEffect(() => {
    handleGetLocation();
  }, [selectedClassDetail?.class_id]);

  useEffect(() => {
    if (statusVerify === "success") {
      dispatch(setStatusVerifyDefault());
      setShowModalOTP(true);
    }

    if (
      user_token &&
      studio_id &&
      Object.keys(selectedClassDetail).length > 0
    ) {
      showModalDetailClass();
      setShowModalOTP(false);
      setShowModalLogin(false);
      handleGetDisbursementSetting();
    }

    if (user_token && studio_id) {
      dispatch(getStudioDetail(user_token, studio_id));
    }
  }, [dispatch, history, statusVerify, studio_id, user_token]);

  useEffect(() => {
    if (studio_detail?.id) {
      handleGetClassCategories();
    }
  }, [studio_detail?.id]);

  useEffect(() => {
    if (paramWP?.end_date || paramWP?.start_date) {
      setState([
        {
          startDate: new Date(paramWP?.start_date),
          endDate: new Date(paramWP?.end_date),
          key: "selection",
        },
      ]);
    }
    if (paramWP?.class_name) {
      setClassName(paramWP?.class_name);
    }
    if (paramWP?.category_ids) {
      setClassCategoryId(paramWP?.category_ids);
    }
  }, [
    paramWP?.category_ids,
    paramWP?.class_name,
    paramWP?.end_date,
    paramWP?.start_date,
  ]);

  const isMobile = window.innerWidth < 768;

  const columns = [
    {
      title: (
        <div className="flex items-center justify-between">
          <span>Class Name</span>
          <span className={"block md:hidden"}>Action</span>
        </div>
      ),
      dataIndex: "class_name",
      key: "class_name",
      className: "class-name-mobile",
      onHeaderCell: () => ({
        style: isMobile
          ? { backgroundColor: colorThemeOpacity, borderRadius: 0 }
          : {},
      }),
      onCell: () => ({
        style: { display: "flex", width: "100%" },
      }),
      render: (text, record) => {
        return (
          <Space size="middle">
            <div className="flex col-class-name w-full">
              <div
                style={{ backgroundColor: colorTheme }}
                className={
                  "w-[50px] h-[50px] md:w-[65px] md:h-[65px] flex items-center justify-center mr-[10px] md:mr-[0] mt-[5px]"
                }
              >
                {record.thumbnail_image === "" ? (
                  <img
                    src={thumbnail}
                    alt=""
                    className="object-contain md:w-[35px] md:h-[35px] w-[30px] h-[30px]"
                  />
                ) : (
                  <img
                    src={thumbnail}
                    alt=""
                    className="object-cover object-center md:w-full md:h-full w-full h-full"
                  />
                )}
              </div>
              <div className={"w-[80%] md:w-[70%]"}>
                <div className="font-[400] md:font-black mb-0 md:ml-[10px] flex justify-between items-start">
                  <span>{text}</span>
                  <div className="w-[24px] block md:hidden">
                    <img
                      className={"w-[16px] pointer"}
                      src={more_icon}
                      alt=""
                    />
                  </div>
                </div>
                <div className="mobile-view-classname mb-0 block md:hidden">
                  <p className="mb-[3px]">
                    {record.class_start_time} - {record.class_end_time}
                  </p>
                  <p className="mb-[3px]" style={{ color: "#888888" }}>
                    {record.teacher}
                  </p>
                  <p className="mb-[3px]">
                    {record.is_online ? (
                      <Tag color="#27AE60">Online</Tag>
                    ) : (
                      <Tag color="#2D9CDB">Onsite</Tag>
                    )}
                  </p>
                </div>
                {record.user_has_booked_class ? (
                  <div
                    style={{ color: colorTheme }}
                    className="button flex justify-center items-center md:hidden !md:w-[136px] !w-[106px] ml-auto"
                  >
                    Registered
                  </div>
                ) : (
                  <div className="wrapper-enroll-mobile">
                    <Button
                      onClick={() => {
                        setSelectedClassDetail(record);
                        showModalDetailClass();
                        setProsessEndroll(true);
                      }}
                      className={"flex md:hidden ml-auto"}
                      type="primary"
                      title="Enroll Now"
                      style={{
                        backgroundColor: colorTheme || "#E26851",
                        borderRadius: 0,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Space>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      responsive: ["md"],
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacjer",
      responsive: ["md"],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      responsive: ["md"],
      render: (text) => {
        return text ? (
          <Tag color="#27AE60">Online</Tag>
        ) : (
          <Tag color="#2D9CDB">Onsite</Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      responsive: ["md"],
      render: (_, record) => {
        if (record.user_has_booked_class) {
          return (
            <div
              style={{ color: colorTheme }}
              className="button flex justify-center items-center"
            >
              Registered
            </div>
          );
        }
        return (
          <div className="wrapper-enroll-mobile">
            <Button
              onClick={() => {
                setSelectedClassDetail(record);
                showModalDetailClass();
                setProsessEndroll(true);
              }}
              className={"hidden md:flex items-center justify-center"}
              type="primary"
              title="Enroll Now"
              style={{
                backgroundColor: colorTheme || "#E26851",
                borderRadius: 0,
              }}
            />
          </div>
        );
      },
    },
  ];

  const showModalDateRange = () => {
    setIsModalDateOpeRange(true);
  };

  const handleOkDateRange = () => {
    setIsModalDateOpeRange(false);
  };

  const handleCancelDateRange = () => {
    setIsModalDateOpeRange(false);
  };

  const showModalDetailClass = () => {
    setIsModalOpenDetailClass(true);
  };

  const handleOkDetailClass = () => {
    setIsModalOpenDetailClass(false);
  };

  const handleCancelDetailClass = () => {
    setIsModalOpenDetailClass(false);
  };

  const handleGetPublicClasses = async () => {
    try {
      const { data } = await getPublicClasses(subdomain);
      setStudioClasses(data.data);
    } catch (error) {}
  };

  const handleGetLocation = useCallback(async () => {
    try {
      const { data } = await getPublicStudioLocation(
        subdomain,
        selectedClassDetail?.class_id || ""
      );
      setStudioLocation(data.data);
    } catch (error) {}
  }, [selectedClassDetail?.class_id, subdomain]);

  const hexToHexWithAlpha = (hex, alpha) => {
    // Hapus tanda # jika ada
    hex = hex.replace("#", "");

    // Pastikan format HEX valid (6 karakter)
    if (hex.length !== 6) return null;

    // Konversi alpha (0 - 1) menjadi nilai HEX (00 - FF)
    let alphaHex = Math.round(alpha * 255)
      .toString(16)
      .padStart(2, "0");

    // Kembalikan dalam format HEX dengan alpha
    return `#${hex}${alphaHex}`;
  };

  const handleGetStudioInfo = async () => {
    try {
      const res = await getStudioInfo(subdomain);

      //console.log(hexToHexWithAlpha(res.data.data.studio_header_color, 0.3));

      setstudio_detail(res.data.data);
      setColorTheme(res.data.data.studio_header_color);
      setColorThemeOpacity(
        hexToHexWithAlpha(res.data.data.studio_header_color, 0.3)
      );
    } catch (error) {}
  };

  const handleGetClassCategories = async () => {
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await sendGetRequest(
        null,
        null,
        `/studios/${studio_detail.id}/categories`,
        config
      );
      setClassCatogories(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDisbursementSetting = async () => {
    try {
      const { data } = await getDisbursementSetting(user_token, studio_id);
      const { payment_gateway, recipient_bank_code } = data.data;

      setPaymentGateway(payment_gateway);
      setBankCode(recipient_bank_code);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = () => {
    dispatch(requestOtp(email, subdomain, true));
  };

  const responseSocialAuth = async (provider, response) => {
    try {
      console.log(response, "response");

      const access_token = response?.accessToken;
      setMethod(provider);
      setAccessToken(access_token);
      setEmail(response.profileObj?.email ?? response?.email);

      if (access_token) {
        const result = await authService.loginWithSocmed(
          provider,
          access_token
        );
        if (result.data.data.token) {
          dispatch(setLoginFromStudio());
          dispatch(setNewUser(result.data?.data));
          setShowModalLogin(false);
        }
      }
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setShowModalRegister(true);
        setRegisterWithSocmed(true);
      }
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const handleRegisterWithSocmed = async () => {
    try {
      const result = await authService.registerWithSocmed(method, accessToken);
      console.log(result, "result");
      if (result.data.data.token) {
        dispatch(setLoginFromStudio());
        dispatch(setNewUser(result.data?.data));
        history.push("/dashboard");
      }
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  const redirectToRegister = () => {
    registerWithSocmed
      ? handleRegisterWithSocmed()
      : history.push("/register-student");
  };

  const onChangeRadio = (e) => {
    setPaymentMethod(e.target.value);
  };

  const getStatusOrder = async () => {
    try {
      const { data } = await sendGetRequest(
        user_token,
        studio_id,
        `/orders/${propsPayment?._id}`
      );
      const { payment_status } = data?.data?.payment;
      if (payment_status === "settlement") {
        setPropsPayment();
      }
      setPropsPayment(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (propsPayment?._id) {
      const intervalId = setInterval(() => {
        getStatusOrder();
      }, 60000); // 60 seconds

      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [propsPayment?._id]);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <OrganismLayout
      studioDetail={studio_detail}
      colorTheme={colorTheme}
      isEmbed={paramWP.embed}
      isBrowseClasses={!user_token}
      title="Class List"
    >
      <div className={`px-[8px] ${!user_token && "xl:px-24"} py-10`}>
        <div className="lg:flex justify-between">
          <h1 className="text-zinc-800 lg:text-[40px] font-bold mb-[30px]">
            Class Offerings
          </h1>

          <Space
            direction={mobileSize ? "vertical" : "horizontal"}
            className={"hidden md:flex"}
          >
            <Space onClick={showModalDateRange}>
              <img alt="" src={calender} width={18} />
              <span className=" pointer custom-hover ms-2">
                {`${moment(state[0].startDate).format(
                  "DD MMM, YYYY"
                )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
              </span>
              <CaretDownOutlined className="text-[10px]" />
            </Space>

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="w-[200px] shadow-sm custom-select"
              placeholder="Categories"
              suffixIcon={<CaretDownOutlined />}
              value={classCategoryId}
              onSelect={(e) => setClassCategoryId(e)}
              onClear={() => setClassCategoryId(null)}
              options={
                classCategories.map((item) => ({
                  label: capitalize(item.name),
                  value: item._id,
                })) || []
              }
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="w-[200px] shadow-sm custom-select"
              placeholder="Classes"
              suffixIcon={<CaretDownOutlined />}
              value={className}
              onSelect={(e) => setClassName(e)}
              onClear={() => setClassName(null)}
              options={
                studioClasses.map((item) => ({
                  label: item.class_name,
                  value: item.class_name,
                })) || []
              }
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="w-[200px] shadow-sm custom-select"
              placeholder="Locations"
              suffixIcon={<CaretDownOutlined />}
              value={locationFilterId}
              onSelect={(e) => setLocationFilterId(e)}
              onClear={() => setLocationFilterId(null)}
              options={
                studioLocation.map((item) => ({
                  label: item.name,
                  value: item.id,
                })) || []
              }
            />

            <Button
              type="primary"
              title="Reset Filter"
              style={{ backgroundColor: colorTheme }}
              onClick={() => {
                setState([
                  {
                    startDate: currentDate,
                    endDate: last,
                    key: "selection",
                  },
                ]);
                setClassName(null);
                setLocationFilterId(null);
                setClassCategoryId(null);
              }}
            />
          </Space>
        </div>

        <Drawer
          title={"Filters"}
          closeIcon={
            <div
              className={
                "border-[1px] border-[#ccc] rounded-full border-solid h-[27px] w-[27px] flex items-center justify-center py-[3px] px-[5px] !group-hover:bg-transparent"
              }
            >
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.9994 7L1 1M1.00064 7L7 1"
                  stroke="#888888"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          }
          placement="right"
          closable={true}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          headerStyle={{ padding: "8px 24px" }}
          className="text-white mobile-drawer"
        >
          <div className="flex flex-column item-drawer">
            <Space onClick={showModalDateRange}>
              <div className="flex border-[2px] border-solid !border-[#CCCCCC] px-[18px] py-[9px] rounded mb-[16px] w-full">
                <img alt="" src={calender} width={18} />
                <span className="pointer text-black custom-hover ms-2 text-[16px]">
                  {`${moment(state[0].startDate).format(
                    "DD MMM, YYYY"
                  )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
                </span>
                <CaretDownOutlined className="text-[10px]" />
              </div>
            </Space>

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="shadow-sm border-solid border-[2px] border-[#ccc] custom-select mb-[16px] text-[16px]"
              placeholder="Categories"
              suffixIcon={<CaretDownOutlined />}
              value={classCategoryId}
              onSelect={(e) => setClassCategoryId(e)}
              onClear={() => setClassCategoryId(null)}
              options={
                classCategories.map((item) => ({
                  label: capitalize(item.name),
                  value: item._id,
                })) || []
              }
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="border-solid border-[2px] border-[#ccc] shadow-sm custom-select mb-[16px] text-[16px]"
              placeholder="Classes"
              suffixIcon={<CaretDownOutlined />}
              value={className}
              onSelect={(e) => setClassName(e)}
              onClear={() => setClassName(null)}
              options={
                studioClasses.map((item) => ({
                  label: item.class_name,
                  value: item.class_name,
                })) || []
              }
            />

            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              className="border-solid border-[2px] border-[#ccc] shadow-sm custom-select mb-[16px] text-[16px]"
              placeholder="Locations"
              suffixIcon={<CaretDownOutlined />}
              value={locationFilterId}
              onSelect={(e) => setLocationFilterId(e)}
              onClear={() => setLocationFilterId(null)}
              options={
                studioLocation.map((item) => ({
                  label: item.name,
                  value: item.id,
                })) || []
              }
            />

            <Button
              type="primary"
              title="Reset Filter"
              className={
                "!border-[1px] !border-solid !border-[#ccc] !text-black !rounded-none bg-transparent !w-full !font-normal !text-[16px]"
              }
              onClick={() => {
                setState([
                  {
                    startDate: currentDate,
                    endDate: last,
                    key: "selection",
                  },
                ]);
                setClassName(null);
                setLocationFilterId(null);
                setClassCategoryId(null);
              }}
            />
          </div>
        </Drawer>

        <div className="flex filters-wrapper-mobile w-full justify-between block md:hidden">
          <Space onClick={showModalDateRange}>
            <div
              className={
                "border-[#cccccc] border border-[2px] shadow-none px-[11px] py-[11px] rounded bg-transparent outline-none focus:!outline-none hover:!outline-none active:!outline-none mr-[18px]"
              }
            >
              <img alt="" src={calender} width={18} />
              <span className=" pointer custom-hover ms-2">
                {`${moment(state[0].startDate).format(
                  "DD MMM, YYYY"
                )} - ${moment(state[0].endDate).format("DD MMM, YYYY")}`}
              </span>
              <CaretDownOutlined className="text-[10px]" />
            </div>
          </Space>

          <button
            className={
              "flex items-center border-[#cccccc] shadow-none px-[11px] py-[11px] rounded bg-transparent outline-none focus:!outline-none hover:!outline-none active:!outline-none"
            }
            onClick={() => setIsOpen(true)}
          >
            <img className="mr-[10px]" src={filters_icon} alt="" />
            Filters
          </button>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg:
                  hexToRGBA(studio_detail?.styles?.header_color, 0.1) ||
                  "#fafafa",
                /* here is your component tokens */
              },
            },
          }}
        >
          <div className="gap-6">
            {class_offerings?.length > 0 ? (
              class_offerings.map((data, idx) => {
                const dataClass = data?.class_schedules.map((el, i) => {
                  return {
                    key: i,
                    class_name: el.class_name,
                    time: el.class_start_time + " - " + el.class_end_time,
                    teacher: el.teachers.join(),
                    type: el.is_online,
                    ...el,
                  };
                });
                return (
                  <div key={idx} className="py-9">
                    <div
                      style={{
                        borderColor: studio_detail?.studio_header_color,
                      }}
                      className="border border-opacity-30"
                    >
                      <div className="px-2 py-4 flex justify-between items-center font-[500] md:font-semibold">
                        <div>{days[moment(data?.class_date).day()]}</div>
                        <div>{moment(data?.class_date).format("LL")}</div>
                      </div>
                      <Table
                        loading={isLoadingClasses}
                        size="middle"
                        columns={columns}
                        dataSource={dataClass}
                        //scroll={{ x: 900 }}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyData />
            )}
          </div>
        </ConfigProvider>
      </div>

      {/* Date */}
      <Modal
        footer={null}
        open={isModalOpenDateRange}
        onOk={handleOkDateRange}
        onCancel={handleCancelDateRange}
      >
        <div className="py-2 text-center">
          <DateRange
            className="shadow-sm mb-3"
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <div className="d-flex justify-content-center ">
            <Button
              type="primary"
              title="Close"
              onClick={handleCancelDateRange}
              style={{
                borderRadius: 0,
                backgroundColor: colorTheme,
              }}
            />
          </div>
        </div>
      </Modal>
      {/* Detail */}
      <Modal
        open={isModalOpenDetailClass}
        onOk={handleOkDetailClass}
        onCancel={handleCancelDetailClass}
        footer={null}
        width={700}
      >
        <ModalDetailClass
          bankCode={bankCode}
          paymentGateway={paymentGateway}
          studioLocation={studioLocation}
          setModalSuccess={setModalSuccess}
          propsPayment={propsPayment}
          setPropsPayment={setPropsPayment}
          redirectToLogin={redirectToLogin}
          prosessEndroll={prosessEndroll}
          setProsessEndroll={setProsessEndroll}
          selectedClassDetail={selectedClassDetail}
          colorTheme={colorTheme}
          locationId={locationId}
          setLocationId={setLocationId}
          confirmMethod={confirmMethod}
          activePlanData={active_plan}
          onChangeRadio={onChangeRadio}
          paymentMethod={paymentMethod}
          // fromOwner={true}
          // student_id={"67512a3ca51e1927fe061222"}
          setIsModalOpenDetailClass={setIsModalOpenDetailClass}
          setSelectedClassDetail={setSelectedClassDetail}
          setPaymentMethod={setPaymentMethod}
          setConfirmMethod={setConfirmMethod}
        />
      </Modal>
      {/* Login */}
      <Modal
        width={400}
        footer={null}
        open={showModalLogin}
        onCancel={() => {
          showModalLogin ? setShowModalLogin(false) : setShowModalOTP(false);
        }}
      >
        {showModalOTP ? (
          <FormOTP subdomain={subdomain} />
        ) : (
          <StudioLoginForm
            colorTheme={colorTheme}
            email={email}
            handleClick={handleClick}
            handleChange={handleChange}
            responseSocialAuth={responseSocialAuth}
            hideBrowseClasses
          />
        )}
      </Modal>

      <ModalLogin
        colorTheme={colorTheme}
        show={showModalRegister}
        title="Email Not Registered"
        label="Continue register with"
        email={email}
        titleButton1={"CHANGE"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalRegister(false)}
        handleSubmit={redirectToRegister}
      />

      {/* <ModalLogin
        show={showModalReactivateStudent}
        title="Welcome Back!"
        label="Continue to reactivate your account"
        email={email}
        titleButton1={"Cancel"}
        titleButton2={"CONFIRM"}
        handleClose={() => setShowModalReactivateStudent(false)}
        handleSubmit={sendRequestOtpActivate}
      /> */}

      <ModalSuccessBookingClass
        propsPayment={propsPayment}
        open={modalSuccess}
        colorTheme={colorTheme}
        setOpen={setModalSuccess}
        selectedClassDetail={selectedClassDetail}
        setSelectedClassDetail={setSelectedClassDetail}
      />
    </OrganismLayout>
  );
};

export default PublicClassList;
