import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { Col, Modal, Row } from "react-bootstrap";

import Button from "../../../../components/Button/button.component";
import AtomLoading from "../../../../components/loding-indicator";

import "./students.styles.scss";
import { getStudents } from "../../../../redux/owner/owner.action";
import axiosInstance from "../../../../axiosInstance";
import { toast } from "react-toastify";
import { GenerateErrorMessage } from "../../../../services/generate-error-message";
import OrganismLayout from "../../../../components/Layout/organism-layout";
import { ConfigProvider, Modal, Popover, Space, Table } from "antd";
import hexToRGBA from "../../../../helper/hexToRbga";
import Dot from "../../../../assets/images/3dots.svg";

export const Students = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [cookies] = useCookies(["user", "subdomain"]);
  const [active] = useState(1);
  const [show1, setShow1] = useState({
    id: "",
    visible: false,
    name: "",
    email: "",
  });

  const { isRegisterStudent } = useSelector((state) => state.studio);
  const { isLoading, students } = useSelector((state) => state.owner);
  const { user, loginFromStudio, studio_detail } = useSelector(
    (state) => state.user
  );

  const user_role = loginFromStudio ? user.role : cookies.user.role;
  const user_token =
    loginFromStudio || isRegisterStudent ? user?.token : cookies.user?.token;
  const studio_id =
    loginFromStudio || isRegisterStudent
      ? user?.studio_id
      : cookies.user?.studio_id;
  const colorTheme = studio_detail?.styles?.header_color;

  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${user_token}`,
      "Studio-Id": studio_id,
    },
  };

  useEffect(() => {
    let params = {
      token_jwt: user_token,
      studio_id: studio_id,
    };
    dispatch(getStudents(params, active));
  }, [active]);

  useEffect(() => console.log(students), [students]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <span
            className=" cursor-pointer flex"
            onClick={() => history.push(`/admin/student/${record?.key}/detail`)}
          >
            {record.avatar && (
              <img
                alt=""
                src={record?.avatar}
                className="w-6 h-6 rounded-full mx-1"
              />
            )}
            {text}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Upcoming",
      dataIndex: "upcoming",
      key: "upcoming",
    },
    {
      title: "Attended",
      dataIndex: "attended",
      key: "attended",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Popover
            placement="bottomRight"
            content={() => (
              <Space direction="vertical">
                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    history.push({
                      pathname: `/admin/student/${record?.key}/detail`,
                    })
                  }
                >
                  Detail
                </span>

                <span
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 font-semibold"
                  onClick={() =>
                    history.push({
                      pathname: `/admin/student/${record?.key}/edit`,
                      state: { id: record?.key },
                    })
                  }
                >
                  Edit Profile
                </span>

                <span
                  onClick={() => {
                    setShow1({
                      id: record?.key,
                      visible: true,
                      name: `${record?.name}`,
                      email: record?.email,
                    });
                  }}
                  className="w-full cursor-pointer p-2 hover:bg-slate-50 text-red-600 font-semibold"
                >
                  Delete
                </span>
              </Space>
            )}
          >
            <img className="cursor-pointer" src={Dot} alt="" />
          </Popover>
        );
      },
    },
  ];

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/students/${show1?.id}`, config);

      let params = {
        token_jwt: user_token,
        studio_id: studio_id,
      };

      setShow1({ visible: false });
      dispatch(getStudents(params, active));
      toast.success("Student account is successfully deleted!");
    } catch (error) {
      toast.error(<GenerateErrorMessage error={error} />);
    }
  };

  return (
    <>
      <OrganismLayout title="Students" maxWidth="44rem">
        {isLoading ? (
          <AtomLoading />
        ) : (
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerBg: hexToRGBA(colorTheme, 0.1) || "#fafafa",
                },
              },
            }}
          >
            <h2 className="font-bold">Students</h2>
            <div className="w-full flex justify-end">
              <Button
                title="Add Student"
                type="primary"
                style={{
                  borderRadius: 0,
                  backgroundColor: colorTheme,
                  margin: "1rem 0",
                }}
                onClick={() => {
                  history.push("/admin/add-student");
                }}
              />
            </div>
            <Table
              columns={columns}
              dataSource={
                students.data &&
                students?.data.map((student) => {
                  return {
                    key: student.id,
                    name: student.first_name + " " + student.last_name,
                    email: student.email,
                    phone: student.phone_number,
                    upcoming: student.upcoming,
                    attended: student.attended,
                    avatar: student.profile_picture,
                  };
                })
              }
            />
          </ConfigProvider>
        )}
      </OrganismLayout>

      <Modal
        open={show1?.visible}
        onOk={() => setShow1({ visible: false })}
        onCancel={() => setShow1({ visible: false })}
        footer={null}
      >
        <div className="text-center p-3 flex flex-col items-center gap-2">
          <h1 className=" font-bold">Delete Student</h1>
          <p className="text-[20px] font-semibold">Delete this Student?</p>
          <div className="border border-slate-300 w-full">
            <p className="m-0 p-4">
              <span className="font-semibold" style={{ color: colorTheme }}>
                {show1?.name}
              </span>

              <br />
              <span className="text-[#475569]">{show1?.email}</span>
            </p>
          </div>
          <div
            style={{ border: "1px solid #E2685147" }}
            className="text-start p-3 flex gap-2 flex-col "
          >
            <b>Please do these steps before deleting the student account!</b>

            <p style={{ color: "#475569" }}>
              Cancel all these payment status (payment verification, pending,
              refund request, and settlement) related to in-progress and
              upcoming order by the student.
            </p>
            <p style={{ color: "#475569" }}>
              Accept all the cancellation requests related to the student.
            </p>
          </div>
          <div
            className="flex flex-col gap-3 mt-4
          "
          >
            <Button
              title="DELETE"
              type="primary"
              onClick={() => handleDelete()}
              style={{
                backgroundColor: "#EB5757",
                borderRadius: 0,
              }}
            />
            <Button
              title="Cancel"
              type="outline-secondary"
              onClick={() => setShow1({ visible: false })}
              style={{
                backgroundColor: "white",
                color: colorTheme,
                border: "none",
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
