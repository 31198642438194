import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import "./register.styles.scss";

import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/Button/button.component";
import TextInput from "../../components/TextInput/text-input.component";
import logo from "./../../assets/images/gigs-logo.svg";

import {
  verifyOtp,
  setStatusVerifyDefault,
  resendOtp,
  setStatusDefault,
} from "../../redux/user/user.action";

const VerifyOtp = ({ nextStep }) => {
  const dispatch = useDispatch();

  const { studio } = useSelector((state) => state.studio);
  const { isLoading, statusVerify, status } = useSelector(
    (state) => state.user
  );

  const [otp, setOtp] = useState("");
  const [remainTime, setRemainTime] = useState(60);

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      email: studio.owner.owner_email,
      password: Number(otp),
    };

    dispatch(verifyOtp(params, null));
  };

  const resendCode = () => {
    dispatch(resendOtp(studio.owner.owner_email, null));
  };

  useEffect(() => {
    if (statusVerify === "success") {
      nextStep();
      dispatch(setStatusVerifyDefault());
    }
  }, [dispatch, nextStep, statusVerify]);

  useEffect(() => {
    if (!remainTime) return;
    const timer = setInterval(() => {
      setRemainTime(remainTime - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainTime]);

  useEffect(() => {
    if (status === "success") {
      setRemainTime(60);
      dispatch(setStatusDefault());
    }
  }, [dispatch, status]);

  return (
    <Row
      // style={{ transform: "translateY(-77px)" }}
      className="justify-content-md-center"
    >
      <Col lg={5}>
        <Form onSubmit={handleSubmit}>
          <div className=" d-flex flex-column align-items-center">
            <img alt="" src={logo} style={{ width: 80 }} />
            <span className="fw-bold mt-2 mb-5">GIGSCLASSROOM</span>
            <h4
              className="fw-bold mt-3 mb-5"
              style={{ fontFamily: "SourceSans3" }}
            >
              Verify Your Account
            </h4>
            <p style={{ width: 350 }} className="regular-text text-center">
              We emailed you 6 (six) digits code to{" "}
              <b>{studio.owner.owner_email}.</b> Enter the code below to confirm
              your email address
            </p>

            <TextInput
              autoFocus
              required
              min={6}
              max={6}
              type="number"
              placeholder="000000"
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              style={{
                fontSize: "24px",
                fontFamily: "SourceSans3",
                color: "#000",
                textAlign: "center",
                width: "50%",
                margin: "0 auto",
              }}
              inputClassName="bg-light"
              value={otp}
            />
            <p className="regular-text text-center my-3">
              Didn't receive the code?{" "}
              {!remainTime ? (
                <u
                  className="fw-bold"
                  style={{
                    color: "#E26851",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={resendCode}
                >
                  Resend Code
                </u>
              ) : (
                <span className="fw-bold" style={{ color: "#E26851" }}>
                  Resend Code in {remainTime} Second.
                </span>
              )}
            </p>
            <Button
              buttonType="submit"
              type="primary"
              title="VERIFY"
              style={{
                backgroundColor: "#E26851",
                borderRadius: 0,
                marginTop: 24,
              }}
            />
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default VerifyOtp;
