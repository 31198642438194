import React, { useState, useCallback, useEffect } from "react";

import { toast } from "react-toastify";
import hexToRGBA from "../helper/hexToRbga";
import { CloseCircleOutlined, MinusCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
const InputMultipleImage = ({
  themeColor = "#E26851",
  title = "Upload some images",
  valuePicture,
  fileList,
  setFileList,
  dataImages,
  setRemoveImages,
  setPreviewPhotos,
}) => {
  const [previewUrl, setPreviewUrl] = useState([]);

  useEffect(() => {
    if (valuePicture?.length > 0) {
      setPreviewUrl(valuePicture);
      setPreviewPhotos(valuePicture);
    }
  }, [valuePicture]);

  const beforeUpload = useCallback((file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";

    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/JPEG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      toast.error("Image must smaller than 1MB!");
    }

    if (isJpgOrPng && isLt2M) {
      const url = URL.createObjectURL(file);
      setPreviewUrl((prev) => [...prev, url]);
      setFileList((prev) => [...prev, file]);
    }
  }, []);

  const handleRemove = (idx) => {
    const removedImageName = dataImages[idx]?.image_name;

    setFileList(fileList?.filter((_, i) => i !== idx));
    setPreviewUrl(previewUrl?.filter((_, i) => i !== idx));
    setPreviewPhotos(valuePicture?.filter((_, i) => i !== idx));

    if (removedImageName) {
      setRemoveImages((prev) => [...prev, removedImageName]);
    }
  };

  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        border: `1px solid ${hexToRGBA(themeColor, 0.2)}`,
      }}
      className="px-8 py-4 my-4"
    >
      <Col span={24} className="flex gap-4">
        {previewUrl.length > 0 ? (
          previewUrl.map((el, idx) => (
            <div className="relative shadow-sm">
              <img src={el} className="w-24 h-24 object-contain" alt="" />
              <MinusCircleFilled
                className="text-red-500 absolute -right-2 top-4"
                onClick={() => handleRemove(idx)}
              />
            </div>
          ))
        ) : (
          <p className="font-semibold m-0">{title}</p>
        )}
      </Col>

      <Col>
        <div className="mt-4">
          <input
            type="file"
            id="photos"
            style={{ display: "none" }}
            onChange={(event) => {
              beforeUpload(event.target.files[0], true);
            }}
          />
          <label
            htmlFor="photos"
            style={{
              backgroundColor: themeColor,
            }}
            className="py-2 px-8 font-bold text-white cursor-pointer hover:opacity-70"
          >
            Browse File
          </label>
          <p className="mt-2 text-xs font-normal" style={{ fontSize: 10 }}>
            Format png, jpg, jpeg, max 2MB each file
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default InputMultipleImage;
