import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import "./owner.styles.scss";

import Button from "../../components/Button/button.component";
import { TweenOneGroup } from "rc-tween-one";
import {
  addIdTeacher,
  addClass,
  removeIdTeacher,
  setStatusDefault,
  getTeacher,
} from "../../redux/owner/owner.action";
import { toast } from "react-toastify";
import { Input, Select, Space, Tag } from "antd";
import AddTeacher from "./add-teacher-component";

const ClassAddTeacherSetup = ({ nextStep, colorTheme }) => {
  const dispatch = useDispatch();

  const {
    addClass: addClassReducer,
    teacher,
    addClassStatus,
    addTeacherStatus,
  } = useSelector((state) => state.owner);
  const { user } = useSelector((state) => state.user);

  const [cookies] = useCookies(["subdomain", "user"]);

  const [addTeacher, setAddTeacher] = useState(false);
  const [isAddTeacher, setIsAddTeacher] = useState(false);

  const user_token = user?.token || cookies?.user.token;
  const studio_id = user?.studio_id || cookies?.user?.studio_id;

  const handleAddIdTeacher = (id) => {
    if (id === "") {
      toast.error("Please Choose a Teacher");
      return;
    } else if (addClassReducer.teacher.find((x) => x.teacher_id === id)) {
      toast.error("Teacher already exist!");
      return;
    }

    let dataTeacher = teacher?.data.find((e) => e.id === id);
    let params = {
      teacher_id: dataTeacher.id,
      first_name: dataTeacher.first_name,
      last_name: dataTeacher.last_name,
    };
    dispatch(addIdTeacher(params));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let teacherIds = "";
    addClassReducer.teacher.map((e, idx) => {
      if (idx === 0) {
        return (teacherIds = e.teacher_id);
      } else {
        return (teacherIds = teacherIds.concat(",", e.teacher_id));
      }
    });

    let params = {
      class_name: addClassReducer.class_name,
      is_online: addClassReducer.is_online,
      location_ids: addClassReducer?.location_ids,
      room: addClassReducer.room,
      slots: addClassReducer.slots,
      max_booking_slots: addClassReducer.max_booking_slots,
      is_available_multiple_booking_slots:
        addClassReducer.is_available_multiple_booking_slots,
      notes: addClassReducer.notes,
      start_date: addClassReducer.start_date,
      end_date: addClassReducer.end_date,
      repeat_every: addClassReducer.repeat_every,
      repeat_duration: addClassReducer.repeat_duration,
      teacher_ids: teacherIds,
      direct_payment_allowed: addClassReducer.direct_payment_allowed,
      credits: addClassReducer.credits,
      all_plans_allowed: addClassReducer.all_plans_allowed,
      class_categories: addClassReducer.class_categories.map((el) => el.label),
      about_the_class: addClassReducer.about_the_class,
      schedule: addClassReducer.schedule,
      thumbnail: addClassReducer.thumbnail,
      images: addClassReducer.images,
    };

    if (!addClassReducer.all_plans_allowed) {
      params.plan_ids = [addClassReducer.plan_ids];
    }

    // Add online class-specific properties
    if (addClassReducer.is_online === 1) {
      params.class_link = addClassReducer.class_link;
      params.additional_information = addClassReducer.additional_information;
    }

    // Add payment-related properties
    if (addClassReducer.direct_payment_allowed) {
      params.class_price = addClassReducer.class_price;
      params.class_tax = addClassReducer.class_tax;
    }

    dispatch(addClass(params, user_token, studio_id));
  };

  useEffect(() => {
    dispatch(getTeacher(user_token, studio_id, 1));
  }, [addTeacherStatus]);

  useEffect(() => {
    if (addClassStatus === "success") {
      dispatch(setStatusDefault());
      nextStep();
    }
  }, [addClassStatus]);

  useEffect(() => {
    console.log(addClassReducer.teacher);
  }, [addClassReducer.teacher]);

  const removeTeacher = (teacher_id) => {
    let array = addClassReducer.teacher;
    const afterDelete = array.filter((e) => e.teacher_id !== teacher_id);
    dispatch(removeIdTeacher(afterDelete));
  };

  const forMap = ({ first_name, last_name, teacher_id }, i) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          console.log(teacher_id);
          removeTeacher(teacher_id);
        }}
      >
        {first_name + " " + last_name}
      </Tag>
    );
    return (
      <span
        key={i}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = addClassReducer?.teacher?.map(forMap);

  return isAddTeacher ? (
    <AddTeacher
      isAddTeacher
      colorTheme={colorTheme}
      setIsAddTeacher={setIsAddTeacher}
    />
  ) : (
    <div className="flex justify-center items-center p-4 flex-col gap-3">
      <div className="flex flex-col md:w-[568px] w-full gap-2">
        <label htmlFor="tearchers" className="text-slate-900 text-lg font-bold">
          Teachers
        </label>

        {addTeacher ? (
          <>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              placeholder="Choose Teacher"
              onChange={(value) => {
                handleAddIdTeacher(value);
              }}
              options={teacher?.data.map((e) => ({
                value: e.id,
                label: e.first_name + " " + e.last_name,
              }))}
              className="h-10"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div
                    onClick={() => setIsAddTeacher(true)}
                    className="w-full flex px-[15px] py-2 items-center text-neutral-800 text-sm font-semibold cursor-pointer hover:bg-slate-50"
                  >
                    Add a Teacher..
                  </div>
                </>
              )}
            />
            <div
              style={{
                marginBottom: 16,
              }}
            >
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: "from",
                  duration: 100,
                }}
                onEnd={(e) => {
                  if (e.type === "appear" || e.type === "enter") {
                    e.target.style = "display: inline-block";
                  }
                }}
                leave={{
                  opacity: 0,
                  width: 0,
                  scale: 0,
                  duration: 200,
                }}
                appear={false}
              >
                {tagChild}
              </TweenOneGroup>
            </div>
          </>
        ) : (
          <div className="flex md:w-[568px] w-full justify-center mt-4">
            <div
              onClick={() => setAddTeacher(true)}
              style={{
                border: `solid 1px ${colorTheme}`,
              }}
              className="flex cursor-pointer w-[186px] h-[43px] py-[15px] border border-blue-800 border-opacity-30 justify-center items-center gap-2.5"
            >
              <div
                style={{ color: colorTheme }}
                className="text-base font-semibold "
              >
                Add New Teacher
              </div>
            </div>
          </div>
        )}

        <Space direction="vertical" className="mt-8">
          <Button
            onClick={handleSubmit}
            type="primary"
            title="ADD CLASS"
            style={{ width: "100%", backgroundColor: colorTheme }}
          />

          <Button
            buttonType="button"
            style={{
              width: "100%",
              backgroundColor: "#fff",
              color: colorTheme,
            }}
            type="secondary"
            title="Skip for now"
            onClick={() => {
              nextStep();
            }}
          />
        </Space>
      </div>
    </div>
  );
};

export default ClassAddTeacherSetup;
