import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { CopyOutlined } from "@ant-design/icons";
import "./text-input.styles.scss";

const TextInput = ({
  value = "",
  type = "text",
  label = "",
  onChange,
  placeholder = "",
  className = "",
  labelClassName = "",
  inputClassName = "",
  style = {},
  disabled = false,
  onKeyUp,
  max,
  min,
  autoFocus = false,
  readOnly = false,
  required = false,
  addTooltip = false,
  userCanCopy = false,
  onClick,
}) => {
  const formatValue = (val) => {
    let valueStr = val?.toString() || "";
    if (type === "number") {
      return valueStr.replace(/\D/g, "").replace(/^0+(\d)$/, "$1");
    }
    if (type === "price") {
      return valueStr.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return valueStr;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Class credits are needed to book classes on our platform using a plan with
      a class pass type.
    </Tooltip>
  );

  return (
    <div className={`form-group ${className} my-2 relative`}>
      {label && (
        <label
          htmlFor={label}
          className={`custom-form-label ${labelClassName}`}
        >
          {label}
          {required && <span className="text-danger">&nbsp;*</span>}
          {addTooltip && (
            <OverlayTrigger
              delay={{ hide: 400 }}
              placement="top"
              overlay={renderTooltip}
            >
              <HiOutlineInformationCircle className="text-warning ms-2" />
            </OverlayTrigger>
          )}
        </label>
      )}

      <input
        id={label}
        required={required}
        type="text"
        value={formatValue(value)}
        onChange={onChange}
        placeholder={placeholder}
        className={`${inputClassName} text-input`}
        style={style}
        readOnly={readOnly}
        disabled={disabled}
        onKeyUp={onKeyUp}
        maxLength={max}
        minLength={min}
        autoFocus={autoFocus}
      />

      {userCanCopy && (
        <CopyOutlined
          className="absolute bottom-0 top-6 right-2"
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default TextInput;
